.sideNav {
  background: #f8f8f8 !important;
}

#MainLogo {
  height: 20px;
}

.sidenav-logo>div>svg {
  height: 18px;
  width: 35px;
  padding-left: 20px;
  cursor: pointer;
  vertical-align: bottom;
}

.sidenav-logo {
  height: 52px !important;
  background-color: #f8f8f8;
  border-bottom: 1px solid rgba(112, 112, 112, 0.28);
  display: flex;
  justify-content: center;
  align-items: center;
}

.sideNav-menu {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 25px;
  color: #712B81 !important;
  font-size: 12px !important;
}

.ant-menu-item-selected {
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 25px;
  color: #50B1A9 !important;
  background-color: #f8f8f8 !important;
}

.sideNav-menu-active {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 25px;
  color: #50B1A9 !important;
  padding-top: 15px;
  background-color: #f8f8f8;
}

.cstm-img-wrapper-svg>svg {
  width: 16px;
  height: 15px;
}

.cstm-img-wrapper-svg>svg>path {
  fill: #712B81;
}

.cstm-img-svg-path-active>svg>path {
  fill: #50B1A9;
}

.cstm-img-wrapper-svg {
  width: 12px;
  color: #712B81 !important;
}

.image-size {
  width: 12px;
  color: #712B81 !important;
}

#anchor-text-properties {
  color: #712B81 !important;
  font-size: 12px;
}

.anchor-text-properties-same-page {
  color: #712B81;
  font-size: 12px;
}

.active {
  color: #50B1A9 !important;
  font-weight: bold;
}

.contactus-modal {
  font-size: 16px;
  margin: 1rem 0 1rem 2.2rem;
  padding-bottom: 20px;
  font-weight: 700;
  color: #054165;
  text-align: left;
}

.contactus-modal>div:nth-child(1) {
  margin-bottom: 12px;
}

.contactus-modal>div:nth-child(3) {
  color: #50B1A9;
}

.ant-menu-vertical .ant-menu-item {
  height: 40px;
  line-height: 20px;
  margin-bottom: 0px !important;
}

.col-9 {
  padding-left: 5px;
}

.col-3 {
  padding-left: 20px;
}

.sideNav-divider-menu {
  padding-top: 0px !important;
  height: 40px !important;
}

.divider-properties {
  opacity: 0.28;
  border: 1px solid rgba(112, 112, 112, 0.28)
}

#sideNav-divider-menu {
  height: 0px !important;
}

.ant-divider-horizontal {
  min-width: 30% !important;
  margin: auto auto 20px auto;
  height: 0px !important;
  width: 0px;
}

a:active {
  color: #50B1A9 !important;
}

#contact-style {
  color: #50B1A9 !important;
  text-decoration: underline;
  font-weight: bold;
  font-size: 14px !important;
}

#contact-inquiries-styles {
  color: #2C2B2E !important;
  font-weight: bold;
  font-size: 14px !important;
}


.bookingroute {
  left: -208px;
  position: absolute;
  z-index: 100;
  position: fixed;
  height: 100%;
  transition: left .5s ease-in-out;
}