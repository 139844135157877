.ant-drawer .ant-drawer-header {
  font-size: 25px;
  letter-spacing: 0.46px;
  background-color: #f9f9f9;
  color: #42413d;
}
.ant-drawer .ant-drawer-title {
  color: #712B81;
  font-weight: bold;
}

.ant-form label {
  font-weight: bold;
  color: #712B81;
  text-transform: uppercase;
}

@media (max-width: 575.98px) {
  .drawer .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.material-input {
  border: 0px;
  font-size: 16px;
  border-radius: 5px !important;
  /* border-bottom: 1px solid #767676; */
}

/* .material-input:focus {
  border: 0px;
  border-radius: 0px;
  border-bottom: 1px solid #767676;
  box-shadow: none;
} */

.profile-label-text {
  color: #767676;
  font-size: 12px;
}

.entrego-select>.ant-select-selection, .entrego-select>.ant-select-selection:hover {
  width: 100%;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  /* border-bottom: 1px solid #767676; */
  box-shadow: none;
}

.ant-row .ant-form-item .ant-form-item-control {
  margin-bottom: 0px;
  line-height: 15px;
}

.mt-3 {
  margin-top: 3rem;
}
.material-input {
  border: 0px;
  font-size: 16px;
  border-radius: 5px !important;
  /* border-bottom: 1px solid #767676; */
}

/* .material-input:focus {
  border: 0px;
  border-radius: 0px;
  border-bottom: 1px solid #767676;
  box-shadow: none;
} */

.profile-label-text {
  color: #767676;
  font-size: 12px;
}

.entrego-select>.ant-select-selection, .entrego-select>.ant-select-selection:hover {
  width: 100%;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  /* border-bottom: 1px solid #767676; */
  box-shadow: none;
}

.ant-row .ant-form-item .ant-form-item-control {
  margin-bottom: 0px;
  line-height: 15px;
}

.mt-3 {
  margin-top: 3rem;
}

.pouchbox-tooltip-style {
  border: none !important;
  background: none !important;
  padding: 0 !important;
  height: 12px !important;
  width: 12px !important;
  margin-left: 5px;
}

.pouchbox-tooltip-style:focus {
  background-color: #FAFAFA !important;
  border-color: #FAFAFA !important;
}

.bankproof-label-text {
  color: #712B81;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.bankproof-text-style {
  color: #712B81;
  font-size: 13px;
}

.upload-proof-btn-style {
  background: #50B1A9 !important;
  width: 460px !important;
  height: 35px !important;
  color: #FFF !important;
  margin-top: 15px;
}

.proof-success-label-style {
  color: #06BF38;
  font-size: 16px;
  margin-top: 10px;
}

.proof-failure-label-style {
  color: #BF391E;
  font-size: 16px;
  margin-top: 10px;
}

.bank-proof-title {
  color: #BF391E;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.bank-proof-content {
  color: #712B81;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  margin-bottom: 20px;
}

.upload-proof-modal-style {
  width: 450px !important
}

.button-align {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.bank-ok-btn-style,
.bank-ok-btn-style:hover,
.bank-ok-btn-style:focus {
  width: 30% !important;
  color: #FFFFFF !important;
  border: 1px solid #712B81 !important;
  background: #712B81 !important;
  height: 50px !important;
  font-size: 14px !important;
}
.header {
  position: relative;
  z-index: 2;
  min-height: 64px;
  background: transparent;
  padding: 10px 0;
  align-items: center;
  /* box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2); */
}

.pointer {
  cursor: pointer;
}

.header a:hover,
.header a:focus {
  text-decoration: none;
  color: #707070;
}

.header a {
  color: #707070;
  /* border: 1px solid white; */
}

.icon-container {
  cursor: pointer;
  display: flex;
  min-width: 30px;
  justify-content: center;
}

.details-contact {
  color: #fff;
}

.logo {
  display: block;
  min-width: 148px;
  height: 40px;
}

.icon-burger {
  display: none !important;
}

.profile-modal {
  display: none;
}

.margin-five {
  margin-right: 3rem !important;
}

.margin-five-rl {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.font-icon {
  font-size: 22px;
}
@media only screen and (max-width: 991px) {
  
  .header {
    justify-content: space-between !important;
    padding: 10px 15px;
  }

  .icon-burger {
    display: block !important;
  }

  .header-collapse-item {
    display: none !important;
  }

  .collapse-item {
    display: none !important;
  }

  .expand-item {
    display: block !important;
  }

  .profile-modal {
    overflow: hidden;
    display: block;
  }

  .profile-modal .ant-modal-body {
    padding: 0;
  }

  .profile-modal .ant-modal-close {
    color: #707070;
  }
  .margin-five {
    margin-right: 8px !important;
  }

  .margin-five-rl {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }
  
}
.ant-dropdown-menu-item img{
  margin-right: 10px;
  width: 16px;
  height: 16px;
}
.ant-dropdown-menu-item a{
  color: #712B81 !important;
}
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
  padding: 7px 15px !important;
  color: #712B81 !important;
}
.anticon.anticon-user, .anticon.anticon-environment, .anticon.anticon-bank, .anticon.anticon-logout{
  padding: 0px 10px 0px 0px;
}
.ant-dropdown-menu hr{
  margin-top:10px;
  margin-bottom:5px;
}
.entrego-footer {
  padding: 0 20px 20px 20px;
}
.login-icons{
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
}
.login-icons > i{
  transform: scale(3);
    font-size: 0;
}
.footer-link {
  color: #fff;
}

.divider-logo {
  position: relative;
  top: -65px;
}

.footer-logo {
  min-height: 28px;
  min-width: 160px;
  margin-bottom: 12px;
}

.footer-mission {
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.21px;
}

.footer-copyright {
  margin-top: 15px;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.21px;
}

.social-plugins {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 11px;
}

@font-face {
  font-family: HelveticaNeueBold;
  src: url(/static/media/HelveticaNeueBold.2493fdb6.ttf);
}
@font-face {
  font-family: HelveticaNeue-Light;
  src: url(/static/media/HelveticaNeue-Light.35321a78.otf);
}
@font-face {
  font-family: HelveticaNeue-Medium;
  src: url(/static/media/HelveticaNeue-Medium.6c6db39a.otf);
}
@font-face {
  font-family: HelveticaNeue-Thin;
  src: url(/static/media/HelveticaNeue-Thin.8625837a.otf);
}
.is-clipped {
  overflow: hidden !important;
}

.footer {
  margin-top: 150px;
  background-color: #712B81;
}

@media only screen and (min-width : 320px) {
  .modal-image {
    width: 320px;
  }
}

@media only screen and (min-width : 480px) {
  .modal-image {
    width: 360px;
  }
}

@media only screen and (min-width : 768px) {
  .modal-image {
    width: 640px;
  }
}

@media only screen and (max-width: 991px) {
  .footer {
    margin-top: 150px;
    padding: 0px;
  }
}

@media only screen and (min-width : 992px) {
  .modal-image {
    width: 640px;
  }
}

/* custom css */
.ant-input {
  background: #FFF;
}

.ant-checkbox-inner {
  background: #FFF;
}

#auth {
  background: #FFF;
}

.ant-layout {
  background: #FFF;
}


.ant-select-selection {
  background: #FFF;
}

.background-primary {
  background-color: #612466;
}

.background-secondary {
  background-color: #00B4AA;
}

.ant-modal-title {
  color: #612466;
  font-weight: bold;
}

.color-primary {
  color: #612466;
}

.color-secondary {
  color: #00B4AA;
}

.text-gray {
  color: #707070;
}

.ant-form-item-label label {
  color: #612466;
  font-weight: bold;
}

.section-color {
  color: #712B81; 
}

thead[class*="ant-table-thead"] th{
  color: #712B81 !important;
  font-weight: 600;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  border: 0;
  background-color: transparent;
}

.ant-pagination-item {
  border: 0;
  background-color: transparent;
  border-radius: 50px;
}

.ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a {
  color: #fff;
}

.ant-pagination-item-active {
  color: #fff;
  background-color: #712B81;
}

.sideNav {
  background: #f8f8f8 !important;
}

#MainLogo {
  height: 20px;
}

.sidenav-logo>div>svg {
  height: 18px;
  width: 35px;
  padding-left: 20px;
  cursor: pointer;
  vertical-align: bottom;
}

.sidenav-logo {
  height: 52px !important;
  background-color: #f8f8f8;
  border-bottom: 1px solid rgba(112, 112, 112, 0.28);
  display: flex;
  justify-content: center;
  align-items: center;
}

.sideNav-menu {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 25px;
  color: #712B81 !important;
  font-size: 12px !important;
}

.ant-menu-item-selected {
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 25px;
  color: #50B1A9 !important;
  background-color: #f8f8f8 !important;
}

.sideNav-menu-active {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 25px;
  color: #50B1A9 !important;
  padding-top: 15px;
  background-color: #f8f8f8;
}

.cstm-img-wrapper-svg>svg {
  width: 16px;
  height: 15px;
}

.cstm-img-wrapper-svg>svg>path {
  fill: #712B81;
}

.cstm-img-svg-path-active>svg>path {
  fill: #50B1A9;
}

.cstm-img-wrapper-svg {
  width: 12px;
  color: #712B81 !important;
}

.image-size {
  width: 12px;
  color: #712B81 !important;
}

#anchor-text-properties {
  color: #712B81 !important;
  font-size: 12px;
}

.anchor-text-properties-same-page {
  color: #712B81;
  font-size: 12px;
}

.active {
  color: #50B1A9 !important;
  font-weight: bold;
}

.contactus-modal {
  font-size: 16px;
  margin: 1rem 0 1rem 2.2rem;
  padding-bottom: 20px;
  font-weight: 700;
  color: #054165;
  text-align: left;
}

.contactus-modal>div:nth-child(1) {
  margin-bottom: 12px;
}

.contactus-modal>div:nth-child(3) {
  color: #50B1A9;
}

.ant-menu-vertical .ant-menu-item {
  height: 40px;
  line-height: 20px;
  margin-bottom: 0px !important;
}

.col-9 {
  padding-left: 5px;
}

.col-3 {
  padding-left: 20px;
}

.sideNav-divider-menu {
  padding-top: 0px !important;
  height: 40px !important;
}

.divider-properties {
  opacity: 0.28;
  border: 1px solid rgba(112, 112, 112, 0.28)
}

#sideNav-divider-menu {
  height: 0px !important;
}

.ant-divider-horizontal {
  min-width: 30% !important;
  margin: auto auto 20px auto;
  height: 0px !important;
  width: 0px;
}

a:active {
  color: #50B1A9 !important;
}

#contact-style {
  color: #50B1A9 !important;
  text-decoration: underline;
  font-weight: bold;
  font-size: 14px !important;
}

#contact-inquiries-styles {
  color: #2C2B2E !important;
  font-weight: bold;
  font-size: 14px !important;
}


.bookingroute {
  left: -208px;
  position: absolute;
  z-index: 100;
  position: fixed;
  height: 100%;
  transition: left .5s ease-in-out;
}
.modified-header {
  position: relative;
  z-index: 2;
  min-height: 50px;
  background: #f8f8f8;
  padding: 0px;
  align-items: center;
  height: 5vh;
  border-bottom: 2px groove rgba(112, 112, 112, 0.28);
}

.search-text::-webkit-input-placeholder {
  color: #054165 !important;
  opacity: 1;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 27px;
}

.search-text::-ms-input-placeholder {
  color: #054165 !important;
  opacity: 1;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 27px;
}

.search-text::placeholder {
  color: #054165 !important;
  opacity: 1;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 27px;
}

.breadcum-style {
  color: #43425D !important;
  font-style: normal;
  font-size: 11px;
  line-height: 22px;
}

.search-text {
  background: rgba(221, 221, 221, 0.51);
  height: 25px;
  border-radius: 0px !important;
}

.cusm-svg-container>svg {
  width: 8px;
  height: 8px
}

.dropdown-style {
  color: #712B81 !important;
  font-style: normal;
  font-size: 10px !important;
  line-height: 15px !important;
  background-color: none !important;
}

.ant-breadcrumb-link {
  color: #712B81 !important;
  font-style: normal;
  font-size: 13px !important;
}

#dropdown-icon-style {
  width: 12px !important;
  height: 12px !important;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background: none !important;
}

.divider-style {
  border: 0.4px solid rgba(112, 112, 112, 0.1);
  width: 40%;
  margin: 3px auto 3px auto !important;
}

.hand-icon-continer {
  text-align: center;
  margin-left: 22px;
}

.hand-icon-continer>svg {
  height: 13px;
  width: 20px;
  vertical-align: bottom;
  cursor: pointer;
}

#MainLogoBooking {
  width: 150px;
  margin-left: 23px;
}
.finalize-popup-spacebar {
  margin: 30px 0 35px 0;
  text-align: center;
  color: #343A40  ;
  font-size: 15px;
}


.session-timeout-modal>.ant-modal-content>.ant-modal-close>.ant-modal-close-x {
  display: none !important;
}

.add-piclup-divider-style {
  background-color: #50B1A9 !important; 
  width: 100% !important;
  height: 1.5px !important;
  align-items: flex-start;
  margin: 5px 0 5px 0 !important;
  margin-bottom: 20px !important;
}

.space-btwn-btns {
  display: flex;
  justify-content: flex-end;
}

.session-stay-btn-style,
.session-stay-btn-style:hover,
.session-stay-btn-style:focus {
  width: 100%;
  color: #FFFFFF;
  background: #712B81 !important;
  height: 40px;
  font-size: 13px;
}

.session-logout-btn-style,
.session-logout-btn-style:hover,
.session-logout-btn-style:focus {
  width: 100%;
  color: #FFFFFF;
  border: 1px solid #868686;
  background: #868686;
  height: 40px;
  font-size: 13px;
}
.material-input {
  border: 0px;
  font-size: 16px;
  border-radius: 5px !important;
  /* border-bottom: 1px solid #767676; */
}

/* .material-input:focus,
.material-input:hover,
.material-input:active {
  border: none;
  border-radius: none;
  border-bottom: 1px solid #767676;
  box-shadow: none;
} */


.add-package-label {
  font-size: 12px;
  color: #712B81;
}

.add-package-label-container {
  margin-bottom: 20px;
}

.card-package-size {
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  padding: 9px;
  color: #707070;
  font-size: 12px;
  margin-top: 10px;
}

.card-shipping-fee .ant-card-body {
  padding: 8px;
}

.card-shipping-price {
  font-size: 24px;
  color: #00B4A9;
}

.card-package-size:focus,
.card-package-size:hover,
.card-package-size-active {
  cursor: pointer;
  color: #fff;
  background-color: #00B4A9;
  border: 1px solid #00B4A9;
}

.btn-remove-border-bottom {
  border-bottom: none;
}

.btn-border-bottom {
  border-bottom: 1px solid #d2d2d2;
}

.font-sm {
  font-size: 12px;
}

.a-tag {
  color: #007bff;
  cursor: pointer;
}

.promocode-input-style {
  border-color: #D9D9D9 !important;
  font-size: 13px !important;
}

.promocode-input-style>input {
  color: #CA4A30 !important;
}

.promocode-input-style:hover>.ant-input:hover {
  border-color: #D9D9D9 !important;
}

.promocode-input-style>.ant-input:focus {
  border-color: #D9D9D9 !important;
  box-shadow: none !important;
}

.promocode-btn-style {
  width: 20% !important;
  color: #fff !important;
  background: #B9B9B9 !important;
  border: 1px solid #D9D9D9 !important;
  font-size: 13px !important;
  border-radius: 0px !important;
}

.promocode-btn-style:active {
  outline: 0 !important;
}

.promocode-btn-style:focus {
  outline: 0 !important;
}

.promocode-heading-styles {
  margin-bottom: 15px !important;
}

.promocode-btn-active-style {
  background-color: #712B81 !important;
  color: white !important;
  border-radius: 0px !important;
}

.promocode-formitem-style {
  margin-bottom: 10px !important;
  width: 100%;
}

.promocode-input-style>.ant-input {
  border-radius: 0px !important;
}

.invalid-txt-style {
  color: #CA4A30;
  font-size: 11px;
}

.valid-txt-style {
  color: #11D045;
  font-size: 11px;
}

.ant-input-clear-icon {
  color: #CA4A30 !important;
}

.textGreen>.ant-input {
  color: #11D045 !important;
}

.font-size {
  font-size: 14px;
}
.font-size {
  font-size: 14px;
}
.ant-modal-title {
    text-align: center;
    font-size: x-large;
}

.ant-modal-header {
    padding: 32px 24px 0px 24px;
    border-bottom: 0px;
}

.ant-modal-body {
    padding: 16px 24px 24px 24px;
}

.ant-modal-footer {
    border-top: 0px;
}
#booking-md-lg table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

#booking-xs-sm {
  display: none;
}

@media only screen and (max-width: 991px) {
  #booking-xs-sm {
    display: none;
  }

  .analytics-container {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  #booking-md-lg {
    display: none;
  }

  #booking-xs-sm {
    display: block;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: Chivo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .fb_customer_chat_bounce_in_v2 {
  left: 0pt !important;
}

.fb_dialog {
  left: 18pt !important;
} */

/* ***************
 * FB on left side 
 ******************/
/* this is for the circle position */
.fb_dialog.fb_dialog_advanced {
  left: 18pt;
}

/* The following are for the chat box, on display and on hide */
iframe.fb_customer_chat_bounce_in_v2 {
  left: 9pt;
}

iframe.fb_customer_chat_bounce_out_v2 {
  left: 9pt;
}
